import React, { MutableRefObject } from 'react';
import './App.css';

interface DevProps {
  localSessionDescription: string | null;
  remoteSessionDescription: string | null;
  logs: string[];
  copySDP: () => void;
}

const Dev: React.FC<DevProps> = ({
  localSessionDescription,
  remoteSessionDescription,
  logs,
  copySDP,
}) => (
  <div style={{ padding: '5%' }}>
    <h1>Developer mode CD/CI</h1>
    <textarea
      id="localSessionDescription"
      readOnly // Use `readOnly` in JSX (not `readonly`)
      value={localSessionDescription || ''}
      style={{ width: '100%', height: '100px' }}
    ></textarea>
    <button onClick={copySDP}>Copy SDP</button>

    <textarea
      id="remoteSessionDescription"
      readOnly
      value={remoteSessionDescription || ''}
      style={{ width: '100%', height: '100px' }}
    ></textarea>

    <h2>Logs</h2>
    <div
      id="logs"
      style={{
        width: '100%',
        height: '200px',
        border: '1px solid #ccc',
        overflowY: 'scroll',
      }}
    >
      {logs.map((log, index) => {
        return <div key={index}>{log}</div>;
      })}
    </div>
  </div>
);

export default Dev;
